import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@environments/environment';
import { CategoryType } from '@type/categories.type';
import { FieldTypeWithOperators } from '@type/field.type';
import { ListWithPagination } from '@type/response.type';
import { joinUrls } from '@utils/urls';
import { Observable, map } from 'rxjs';

@Injectable()
export class CategoryApiService {
  private http = inject(HttpClient);

  getCategoryProperties(): Observable<FieldTypeWithOperators> {
    const { categories, properties } = environment.urls;
    return this.http
      .get<ListWithPagination<FieldTypeWithOperators>>(joinUrls(categories, properties))
      .pipe(map(({ fields, meta }) => ({ fields, meta })));
  }

  updatePriorities(categories: CategoryType[], concept_id: number): Observable<CategoryType> {
    return this.http.put<CategoryType>(joinUrls(environment.urls.categories, environment.urls.priorities), {
      categories,
      concept_id,
    });
  }
}
